import { EmailCan } from './email.model';

export class MeetEvent{
    public id: string; //set in the goldmine service
    public location: string;
    public ondate: string;
    public convertOnDate: Date; //not coming from API
    public enddate: string;
    public convertEndDate: Date; //not coming from API
    public isCollapsed: boolean; //not comign from API
    public weekDays: Array<WeekDay>;
    public signupMeetings: Array<any>;
    public meetingCount: number;
}

export class WeekDay{
    date: string;
    convertDate: Date; //not coming from API
    tradeShowCount: number;
    appCount: number;
    meetings: Array<Meeting>;
}

export class Meeting{
    //MEET MASTER ONLY
    public mid: number;
    public primaryContact: [{ //pulled from attendees table
        full_name: string,
        phone1: string,
        phone2: string,
        email: string,
        title: string
    }];
    public convertDateTime: string; //Converted ondate + ontime
    public formalType: string; //Formal name for the appointment/show type.
    public isTradeShow: boolean; //Is a trade show or not.
    public isMultiCompany: boolean;
    public linkedMeeting: LinkedMeeting;
    public is_planner: string; //Was this meeting set up using the planner? "1" || "0"
    public their_platform: boolean; //Is this meeting using customers video-client?

    public zoomInfo: {
        mid: number;
        register_link: string,
        join_link: string,
        id: string,
        pass: string,
        record_link: string,
        record_pass: string
    };
    /**
     * Custom emails get loaded and saved by the Email Component
     */
    public cusPreEmails: EmailCan[];
    public cusPostEmails: EmailCan[];
    public attendeeCount: number;
    //FROM CAL TABLE
    public actvcode: string; //For tradeshows
    public accountno: string;
    public ondate: string;
    public ontime: string;
    public enddate: string;
    public ref: string;
    public rectype: string;
    public recid: string;
    public convertedNotes: string;
    //FROM CONTACT1 TABLE
    public masterRecid: string;
    public macola: string;
    public company: string;
    public city: string;
    public state: string;
    public zip: string;
    public address1: string;
    public address2: string;
    public key1: string;
    public key3: string;
    public key4: string;
    //FROM CONTACT2 TABLE
    public ucss: string;
    public ucss2: string;
    public UPOTENTIAL: string;
}

export class LinkedMeeting{
    public child_mid: number;
    public full_name: string;
    public ts_reference: string;
}

/**
Example on 8/15/19. Before the tradeshow additions.

[  
   {  
      "location":"Survey Event Testing",
      "ondate":"2019-07-07 00:00:00.000",
      "enddate":"2019-07-13 00:00:00.000",
      "weekDays":[  
         {  
            "date":"2019-07-10 00:00:00.000",
            "meetings":[  
               {  
                  "accountno":"B9032736108(*,:Q<Mas",
                  "ondate":"2019-07-10 00:00:00.000",
                  "ontime":"17:30",
                  "enddate":"2019-07-10 00:00:00.000",
                  "ref":"Survey Test Appointment",
                  "rectype":"A",
                  "recid":"LEDMXB2%8^SRWRN",
                  "masterRecid":"L6V13IV!JM@%<DI",
                  "macola":"90987877",
                  "company":"Awards Are Us",
                  "city":"Winter Park",
                  "state":"FL",
                  "zip":"32789",
                  "address1":"123 Main St",
                  "address2":"",
                  "key1":"Distributor-Cust(01)",
                  "key3":"Tom Warkel (TW)",
                  "key4":"Nick Kinney (NWK)",
                  "ucss":"Brenda Peralta (BMP)",
                  "ucss2":"Eric Wiberg (EW)",
                  "UPOTENTIAL":"T3",
                  "convertedNotes":"Survey Test Appointment",
                  "mid":"158",
                  "primaryContact":[  
                     {  
                        "full_name":"Bill Johnston",
                        "phone1":"(555)555-0001",
                        "phone2":"",
                        "email":"beccad@crystal-d.com"
                     }
                  ],
                  "attendeeCount":2,
                  "convertDateTime":"2019-07-10T17:30"
               }
            ],
            "convertDate":"2019-07-10T05:00:00.000Z"
         }
      ],
      "id":"RqHRWzD",
      "isCollapsed":false,
      "convertOnDate":"2019-07-07T05:00:00.000Z",
      "convertEndDate":"2019-07-13T05:00:00.000Z"
   }
]
 */