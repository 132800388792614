import { NgModule, Pipe, PipeTransform, Injectable } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

import { RouteGuard } from '@app/route.guard';
//child components, this is the main entry point to the app
import { TripOverviewComponent } from './tripoverview/tripoverview.component';
import { PlannerComponent } from './tripoverview/planner.component';
import { SplashComponent } from './splash.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { MeetingDetailsComponent } from './meetingdetails/meetingdetails.component';
import { AttendeesComponent } from './meetingdetails/attendees/attendees.component';
import { NotesComponent } from './meetingdetails/notes/notes.component';
import { FollowupComponent } from './meetingdetails/followup/followup.component';
import { NguCarouselModule } from '@ngu/carousel';
import { CollapseModule } from '@app/core/collapse';
import { 
    MatSidenavModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatGridListModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
} from '@angular/material';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from 'ngx-mat-datetime-picker';

const routes: Routes = [
    {
        path: 'splash',
        component: SplashComponent,
        canActivate: [RouteGuard],
        children: [
            //trip overview
            { path: 'planner',component: TripOverviewComponent },
            { path: 'planner/week-overview', component: PlannerComponent  },
            { path: 'details',component: MeetingDetailsComponent },
            { path: 'details/attendees', component: AttendeesComponent },
            { path: 'details/notes', component: NotesComponent, children : [
                { path: ':aid', component: NotesComponent}
            ]},
            { path: 'details/followup', component: FollowupComponent },
            { path: 'details/postEmail', component: PostEmailComponent }
        ]
    }
];

//https://stackoverflow.com/questions/31490713/iterate-over-object-in-angular
@Pipe({ name: 'values',  pure: false })
export class ValuesPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    return Object.keys(value).map(key => value[key]);
  }
}


import { CatalogComponent } from './meetingdetails/followup/modules/catalog.module.component';
import { PostEmailComponent } from './meetingdetails/post-email/post-email.component';
import { CanDeactivateGuard } from './auth/can-deactivate/can-deactivate.guard';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { HttpClientModule } from '@angular/common/http';




@NgModule({
    declarations: [
        // ValuesPipe,
        SplashComponent,
        TripOverviewComponent,
        NotfoundComponent,
        MeetingDetailsComponent,
        AttendeesComponent,
        NotesComponent,
        FollowupComponent,
        CatalogComponent,
        PostEmailComponent,
        PlannerComponent
    ],
    imports: [
        NguCarouselModule,
        BrowserAnimationsModule,
        SwiperModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        CollapseModule,
        MatSidenavModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatIconModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatGridListModule,
        MatOptionModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatRadioModule,
        HttpClientModule,
        MatDatepickerModule,
        NgxMatTimepickerModule,
        MatButtonModule,
        NgxMatNativeDateModule,
        NgxMatDatetimePickerModule,
        RouterModule.forChild(routes)
    ],
    providers: [
        CanDeactivateGuard,
        NgxMatNativeDateModule
    ],
    entryComponents: [
        CatalogComponent
    ],
    exports: [RouterModule]
})

export class SplashModule { }