export class Attendee{
    public aid: number; //attendee id
    public mid: number; //meeting id
    public gm_account_num: string;
    public title: string;
    public email: string;
    public full_name: string;
    public phone1: string;
    public phone2: string;
    public state: string;
    public service_team_flyer: number;
    public is_meeting_contact: number;
    public is_confirmed: number;
    public mark_email_change: number; //Change detector for email module.
    public touched: boolean; //Change detector for updating attendees.
    public color: string; //Color for followup module.
    public sent_pre_email: number;
    public sent_post_email: number;
    constructor(resBody: any){
        //Numbers
        this.aid = resBody.aid;
        this.mid = resBody.mid;
        this.service_team_flyer = resBody.service_team_flyer;
        this.is_meeting_contact = resBody.is_meeting_contact;
        this.mark_email_change = resBody.mark_email_change;
        this.is_confirmed = resBody.is_confirmed;
        this.sent_pre_email = resBody.sent_pre_email;
        this.sent_post_email = resBody.sent_post_email;
        //Strings
        this.title = resBody.title? resBody.title.trim() : "";
        this.gm_account_num = resBody.gm_account_num? resBody.gm_account_num.trim() : "";
        this.email = resBody.email? resBody.email.trim() : "";
        this.full_name = resBody.full_name? resBody.full_name.trim() : "";
        this.phone1 = resBody.phone1? resBody.phone1.trim() : "";
        this.phone2 = resBody.phone2? resBody.phone2.trim() : "";
        this.state = resBody.state? resBody.state.trim() : "";
        this.color = resBody.color? resBody.color.trim() : "";
        //NOT COMING FROM API
        this.touched = false;
    }
}

//Attendee class with Less data.
export class LinkedAttendee{
    public aid: number; //attendee id
    public mid: number; //meeting id
    public gm_account_num: string;
    public title: string;
    public email: string;
    public full_name: string;
    public phone1: string;
    public phone2: string;
    public state: string;
    public service_team_flyer: number;
    public is_meeting_contact: number;
    public is_confirmed: number;
    public sent_pre_email: number;
    public sent_post_email: number;
}