export class FollowupMenu{
    public category: string;
    //some categories can be grouped, the subActions array will hold them if that's the case
    public subActions: any[] = [];
    public acid: number;
}

//This will get dumped into "meetings" table "action_temp" field
export class FollowupModule{
    public category: string;
    public toggle: boolean;
    public recipients: { aid: number, name: string, color: string}[];
    public subActions: any[];
    public isComplete: boolean;
    public acid: number;
    public uniqueId: string;
    constructor(subActions, category, acid, uniqueId, toggle = true){
        this.category = category;
        this.acid = acid;
        this.uniqueId = uniqueId;
        this.toggle = toggle;
        this.recipients = [];
        this.subActions = subActions;
        this.isComplete = false;
    }
}