export class User{
    public id: number;
    public username: string;
    public email: string;
    public full_name: string;
    public token: string;
    constructor(
        id: number,
        username: string,
        email: string,
        full_name: string,
        token?: string
    ) {}
}