export class EmailAttachment{
    public acid: number;
    public name: string;
    public description: string;
    public action_type: number;
    public is_territory: boolean;
    public is_default: boolean;
}

//Model for the email_temp column in the meetings table
export class EmailTemp{
    public cusPreEmails: EmailCan[]
    public cusPostEmails: EmailCan[]
    public emailActions: EmailAction[];
}

export class EmailAction{
    public aid: number;
    public index: number; //Relates this data to the attendees array
    public hasEmail: boolean;
    public onHold: boolean;
    public preEmail: {
        emid: number;
        name: string;
        isSent: boolean;
    };
    public postEmail: {
        emid: number;
        name: string;
        isSent: boolean;
    };
    public attachments: number[]; //List of attachments from the actions table
    public allAttachments: {
        acid: number;
        name: string;
        description: string;
        action_type: number;
        isAttached: boolean;
    }[];
    constructor(aid, index, preEmail, sent_pre_email, sent_post_email, 
        hasEmailFunction, postEmailFunction, attachmentsFunction){
        
        this.aid = aid;
        this.index = index;
        this.preEmail = {
            emid: preEmail.emid,
            name: preEmail.name,
            isSent: Boolean(sent_pre_email)
        };
        this.onHold = false;
        //Functions
        this.hasEmail = hasEmailFunction();
        let postEmail: EmailCan = postEmailFunction();
        this.postEmail = {
            emid: postEmail.emid,
            name: postEmail.name,
            isSent: Boolean(sent_post_email)
        };
        this.attachments = attachmentsFunction();
    }
}

export class EmailCan{ //canned email messages
    public emid: number;
    public is_default: string;
    public user_default: string;
    public key: number;
    public area: string;
    public name: string;
    public subject: string;
    public body: string;
    constructor(emailCanObject){
        this.emid = emailCanObject.emid;
        this.key = emailCanObject.key;
        this.area = emailCanObject.area;
        this.name = emailCanObject.name;
        this.subject = emailCanObject.subject;
        this.body = emailCanObject.body;
    }
}
