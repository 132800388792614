import { Component, Output, EventEmitter, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { FollowupModule } from '@app/core/models/followup.model';
import { Attendee } from '@app/core/models/attendees.model';
import { ViewRef } from '@angular/core/src/render3/view_ref';
import { FollowupService } from '@app/core/services/followup.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'catalog.module.component.html',
    styleUrls: ['../followup.component.scss']
})


export class CatalogComponent implements OnInit, OnDestroy {
    @Output() listener = new EventEmitter<any>(); //any information sent to the parent
    private index: number; //Not a reference
    public attendees: Attendee[]; //Not a reference
    private self: ViewRef<any>; //a reference to self for removing later
    
    public action: FollowupModule; 
    private subs: Subscription[] = [];

    constructor(
        private followupService: FollowupService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(){
        //A direct reference
        this.action = this.followupService.actionsToPerform[this.index];
        //If attendee was unconfirmed or removed, take them off the action recipients array
        this.action.recipients.forEach((person, index)=>{
            let result = this.attendees.find(p => p.aid == person.aid)
            if(!result){
                this.action.recipients.splice(index, 1);
            }
        });
        this.subs.push(
            this.followupService.sendSingleActionSubject.subscribe(
                () => {
                    this.cd.detectChanges();
                }
            )
        );
    }

    /**
     * CALLS THE PARENT COMPONENT TO SET ALL OTHER MODULES => TOGGLE = FALSE
     */
    public appendOnly() {
        this.listener.next({ method: 'appendOnly', uniqueId: this.action.uniqueId});
    }

    public removeRecipient(index: number){
        this.action.recipients.splice(index, 1);
    }

    public addActionRecipientsAll(){
        this.action.recipients.length = 0;
        this.attendees.forEach((person) => {
              this.action.recipients.push({
                aid: person.aid,
                name: person.full_name,
                color: person.color
              });
          });
    }

    public addActionRecipient(index: number){
        let attendee = this.attendees[index];
        if(!this.action.recipients.find((person) => { return person.aid == attendee.aid})){
            this.action.recipients.push({
              aid: attendee.aid,
              name: attendee.full_name,
              color: attendee.color
            });
          }
    }

    public removeSelf(){
        this.listener.next({ 
            method: 'removeSelf', 
            viewRef: this.self,
            uniqueId: this.action.uniqueId
        });
    }

    public sendSelf(){
        this.listener.next({ 
            method: 'sendSelf', 
            viewRef: this.self,
            uniqueId: this.action.uniqueId
        });
    }

    ngOnDestroy(){
        this.subs.forEach(element => {
            element.unsubscribe();
        });
    }
}